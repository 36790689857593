/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'products/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PRODUCT = `${PATH}TOGGLE_EDIT_PRODUCT`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const PRODUCTS = {
  CREATE_PRODUCT: `${PATH}CREATE_PRODUCT`,
  CREATE_PRODUCT_SUCCESS: `${PATH}CREATE_PRODUCT_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PRODUCT: `${PATH}DELETE_PRODUCT`,
  DELETE_PRODUCT_SUCCESS: `${PATH}DELETE_PRODUCT_SUCCESS`,
  EDIT_PRODUCT_SUCCESS: `${PATH}EDIT_PRODUCT_SUCCESS`,
  EDIT_PRODUCT: `${PATH}EDIT_PRODUCT`,
  EXPORT_PRODUCTS: `${PATH}EXPORT_PRODUCTS`,
  EXPORT_PRODUCTS_SUCCESS: `${PATH}EXPORT_PRODUCTS_SUCCESS`,
};

// actions
export const productsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PRODUCT, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(PRODUCTS.GET_STATE_FROM_API),
  createProduct: createAction(PRODUCTS.CREATE_PRODUCT, 'controls'),
  editProduct: createAction(PRODUCTS.EDIT_PRODUCT, 'id', 'controls'),
  deleteProduct: createAction(PRODUCTS.DELETE_PRODUCT, 'id'),

  exportProducts: createAction(PRODUCTS.EXPORT_PRODUCTS),
};

const defaultInvalidInputs = {
  name: false,
  code: false,
};

const initialState = {
  formControls: {
    name: '',
    code: '',
    varietyId: null,
  },
  invalidControls: defaultInvalidInputs,
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const products = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.code = '';
    state.formControls.varietyId = null;
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PRODUCT](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.formControls.code = action.item.code;
    state.formControls.varietyId = action.item.varietyId;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  // api
  [PRODUCTS.CREATE_PRODUCT_SUCCESS](state) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [PRODUCTS.DELETE_PRODUCT_SUCCESS](state) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [PRODUCTS.EDIT_PRODUCT_SUCCESS](state) {
    state.formControls.name = '';
    state.formControls.code = '';
    state.formControls.varietyId = '';
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default products;
